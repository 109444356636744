import React, { useEffect } from 'react';
import { loadScript } from '@treatwell/ui';
import styles from './StoryStream.module.css';

export const StoryStreamWidget = () => {
  useEffect(() => {
    loadScript({
      src: 'https://apps.storystream.ai/app/js/Yzk1YzZiMmExNDE2ZWI3YTBj.js',
      id: 'story-stream',
    });
  }, []);

  return <div id="stry-wrapper" className={styles.root} />;
};

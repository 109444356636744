import React from 'react';
import styles from './HeroBanner.module.css';

function mapVerticalAlignmentToJustifyContent(verticalAlignment: string) {
  switch (verticalAlignment) {
    case 'top':
      return 'flex-start';
    case 'bottom':
      return 'flex-end';
    case 'middle':
    default:
      return 'center';
  }
}

interface Props {
  backgroundImages: { mobile2x: string; desktop1x: string; desktop2x: string };
  backgroundColor: string;
  headline: string;
  headlineVerticalAlignment: string;
  children: React.ReactElement;
}

export function HeroBanner({
  headlineVerticalAlignment = 'middle',
  ...props
}: Props): React.ReactElement {
  const heroBannerLayoutBreakpoint = parseInt(
    styles.heroBannerLayoutBreakpoint,
    10
  );
  const splitHeadline = props.headline.split('\n').slice(0, 3);
  const headlineJustifyContent = mapVerticalAlignmentToJustifyContent(
    headlineVerticalAlignment
  );

  return (
    <div
      className={styles.container}
      style={{ backgroundColor: props.backgroundColor }}
    >
      <div className={styles.pageWidthWrapper}>
        <div className={styles.backgroundContainer}>
          <div className={styles.backgroundWrapper}>
            <picture className={styles.backgroundImage}>
              <source
                media={`(max-width: ${heroBannerLayoutBreakpoint - 1}px)`}
                srcSet={`${props.backgroundImages.mobile2x}`}
              />
              <source
                media={`(min-width: ${heroBannerLayoutBreakpoint}px)`}
                srcSet={`${props.backgroundImages.desktop1x} 1x, ${props.backgroundImages.desktop2x} 2x`}
              />
              <img
                src={props.backgroundImages.desktop1x}
                alt=""
                fetchPriority="high"
              />
            </picture>
          </div>
        </div>
        <div className={styles.foregroundContainer}>
          <div
            className={styles.headlineContainer}
            style={{ justifyContent: headlineJustifyContent }}
          >
            <h1 className={styles.headline}>
              {splitHeadline.map((str, index) => (
                <span key={index}>{str.trim()}</span>
              ))}
            </h1>
          </div>
          <div className={styles.childrenContainer}>{props.children}</div>
        </div>
      </div>
    </div>
  );
}

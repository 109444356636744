import { mParticleEventType } from '@treatwell/ui';
import { trackEvent } from 'js/helpers/mParticleTracking';
import debounce from 'p-debounce';

export const debounceTrackMParticleSearch = debounce(
  ({
    country,
    isWebMobile,
    triggerContext,
    searchType,
    searchTerm,
    language,
  }: {
    country: string;
    isWebMobile: boolean;
    triggerContext: 'browse' | 'home';
    searchType: 'treatment' | 'venue';
    searchTerm: string;
    language: string;
  }) => {
    trackEvent({
      eventName: 'no_matching_results_shown',
      eventType: mParticleEventType.Other,
      eventAttributes: {
        country,
        platform: 'web',
        is_web_mobile: isWebMobile,
        trigger_context: triggerContext,
        search_type: searchType,
        search_term: searchTerm,
        language,
      },
    });
  },
  300
);
